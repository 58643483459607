import { Transition } from '@headlessui/react'
import { CodeIcon, DatabaseIcon, DocumentTextIcon, ExclamationCircleIcon, EyeIcon, PaperClipIcon, TrashIcon, XIcon } from "@heroicons/react/outline";
import { RefObject, useContext, useEffect, useRef, useState } from "react";
import { deleteData, OAuthError, patchData, postData, putData } from "../backend";
import { WorkflowContext, useModal, LastFocusedEditorContext } from "../contexts";
import { BLOCK_ICON_MAP, MIMETYPE_ICON_MAP } from "../utils";
import useUser from "../hooks/useUser";
import { deserializeFromText, serializeToText } from "../emailEditorHelpers";
import { Email, Attachment, AttachmentType } from "../interfaces";
import WorkflowSourceFieldTable from "./WorkflowSourceFieldTable";
import ToggleButton from "./ToggleButton";
import EmailEditor, { EmailEditorRef } from "./EmailEditor";
import ProLabel from "./ProLabel";
import PayWallModalPro from "./PayWallModalPro";
import TemplatedTextEditor, { TemplatedTextEditorRef } from "./TemplatedTextEditor";
import WorkflowEmailToolbarButton from "./WorkflowEmailToolbarButton";
import WorkflowModalImage from "./WorkflowModalImage";
import WorkflowBlockModalSave from './WorkflowModalSave';
import WorkflowSelectModal from './WorkflowSelectModalAdd';
import OAuthModal from './OAuthModal';
import OAuthModalMicrosoft from './OAuthModalMicrosoft';
import EmailAliasPopover from './EmailAliasPopover';
import BetaLabel from './BetaLabel';
import EmailModalAttachment from './EmailModalAttachment';
import WorkflowModalLink from './WorkflowModalLink';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import TransitionWorkflowPage from './transitions/TransitionWorkflowPage';
import TransitionWorkflowPageSideBar from './transitions/TransitionWorkflowPageSideBar';
import WorkflowSelectModalAdd from './WorkflowSelectModalAdd';
import HTMLEmailEditor from './HTMLEmailEditor';
import HTMLPreviewModal from './HTMLPreviewModal';
import EmailInputModeModal from './EmailInputModeModal';
import WorkflowPaymentConfig from './WorkflowPaymentConfig';

type SaveState = "INITIAL" | "HAS_CHANGES" | "SAVING" | "SAVED";

function WorkflowEmail() {
  const { user, hasPermission } = useUser();

  const location = useLocation();
  const history = useHistory();
  const { url } = useRouteMatch();

  const workflowContext = useContext(WorkflowContext)
  const { workflow, mutateWorkflow } = workflowContext;

  const allowEditing = hasPermission(workflow.team, "workflows.edit");

  const index = (() => {
    const hash = location.hash.split("#")[1];
    return (parseInt(hash) || 1) - 1;
  })();

  const initialEmail = workflow.emails[index] ?? workflow.emails[0];
  const attachments = (initialEmail ?? []).attachments;

  const editorTo = useRef<TemplatedTextEditorRef>(null);
  const editorCc = useRef<TemplatedTextEditorRef>(null);
  const editorBcc = useRef<TemplatedTextEditorRef>(null);
  const editorSubject = useRef<TemplatedTextEditorRef>(null);
  const editorContent = useRef<EmailEditorRef>(null);

  const formattingMenuContainer = useRef<HTMLDivElement | null>(null);

  const [sanitizedHTML, setSanitizedHTML] = useState('');

  const [lastFocusedEditor, setLastFocusedEditor] = useState<RefObject<EmailEditorRef | TemplatedTextEditorRef> | null>(editorTo);
  const [showFormattingMenu, setShowFormattingMenu] = useState(false);

  const [addingAttachment, setAddingAttachment] = useState<boolean>(false);
  const [removingAttachmentId, setRemovingAttachmentId] = useState<string>();
  const [updatingAttachmentId, setUpdatingAttachmentId] = useState<string>();

  const [email, setEmail] = useState<Email>();
  const [saveState, setSaveState] = useState<SaveState>("INITIAL");

  const [hasCheckedScopes, setHasCheckedScopes] = useState(false);

  const [emptyToField, setEmptyToField] = useState(false);
  const [emptySubjectField, setEmptySubjectField] = useState(false);

  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);

  const { openModal, closeModal } = useModal();

  const isWorkflowOwner = (workflow.owner?.id === user?.id);

  useEffect(() => {
    if (!initialEmail)
      return;

    setEmail(initialEmail);
    setShowCc(initialEmail.emailCc.length > 0);
    setShowBcc(initialEmail.emailBcc.length > 0);

    // Set email with defaults from existing values or user's defaults
    setEmail({ ...initialEmail });
  }, [user, workflow, initialEmail]);

  useEffect(() => {
    if (hasCheckedScopes || !initialEmail || !location.pathname.endsWith("/email"))
      return;

    if (initialEmail.emailType === "GMAIL")
      postData("/auth/scopes/", { scopes: ["https://www.googleapis.com/auth/gmail.send"] })
        .catch(error => {
          if (error instanceof OAuthError)
            openModal(<OAuthModal title='Portant requires your permission to send emails' oauthUrl={error.oauthUrl} />);
        })
        .finally(() => setHasCheckedScopes(true));

    else if (initialEmail.emailType === "OUTLOOK")
      postData("/microsoft/access-token/", {})
        .catch(error => {
          if (error instanceof OAuthError)
            openModal(<OAuthModalMicrosoft oauthUrl={error.oauthUrl} />);
        })
        .finally(() => setHasCheckedScopes(true));

  }, [hasCheckedScopes, openModal, initialEmail, location]);

  async function updateEnableTracking(enableTracking: boolean) {
    if (!user?.subscription?.featureSignatures) // TODO: Change this to its own feature flag
      return openModal(<PayWallModalPro />);

    let updatedEmail = { ...email!, enableTracking };
    setEmail(updatedEmail);

    updatedEmail = await putData(`/workflows/emails/${initialEmail.id}/`, updatedEmail);
    setEmail(updatedEmail);
  }

  async function updateSendFromOwner(sendFromOwner: boolean) {
    let updatedEmail = { ...email!, sendFromOwner };
    setEmail(updatedEmail);

    updatedEmail = await putData(`/workflows/emails/${initialEmail.id}/owner-update/`, updatedEmail);
    setEmail(updatedEmail);
  }

  function updateEmailField(field: string, value: any) {
    setSaveState("HAS_CHANGES");
    setEmail({ ...email!, [field]: value });
  }

  async function handleSave() {
    let updatedEmail: Email = {
      ...email!,
      emailTo: editorTo.current?.getValue() ?? "",
      emailCc: editorCc.current?.getValue() ?? "",
      emailBcc: editorBcc.current?.getValue() ?? "",
      emailSubject: editorSubject.current?.getValue() ?? "",
      emailContent: email?.isHtmlMode ? sanitizedHTML : editorContent.current?.getValue() ?? "",
    }

    setSaveState("SAVING");

    updatedEmail = await putData(`/workflows/emails/${initialEmail.id}/`, updatedEmail);
    setEmail(updatedEmail);

    setSaveState("SAVED");

    await mutateWorkflow();
  }

  function handleIncludeBrandingToggle(includeBranding: boolean) {
    if (!user?.subscription?.featureRemoveBranding)
      openModal(<PayWallModalPro reason="BRANDING" />);
  }

  function addAttachment(attachmentType: AttachmentType, payload: any) {
    closeModal();
    setAddingAttachment(true);

    let options: Record<string, string> = {};
    if (attachmentType === "INSERT_OUTPUT_AS_LINK")
      options["sharingMode"] = "VIEWER";

    postData(`/workflows/emails/${initialEmail.id}/attachments/`, { attachmentType, ...payload })
      .then(() => mutateWorkflow())
      .catch(console.error)
      .finally(() => setAddingAttachment(false));
  }

  function removeAttachment(attachmentId: string) {
    closeModal();
    setRemovingAttachmentId(attachmentId);

    deleteData(`/workflows/attachments/${attachmentId}/`)
      .then(() => mutateWorkflow())
      .catch(console.error)
      .finally(() => setRemovingAttachmentId(undefined));
  }

  function updateAttachment(attachmentId: string, sharingMode: string) {
    setUpdatingAttachmentId(attachmentId);

    patchData(`/workflows/attachments/${attachmentId}/`, { sharingMode })
      .then(() => mutateWorkflow())
      .catch(console.error)
      .finally(() => setUpdatingAttachmentId(undefined));
  }

  function toggleFormattingMenu() {
    if (editorContent.current && 'focus' in editorContent.current) {
      editorContent.current?.focus();
      setShowFormattingMenu(f => !f);
    }
  }

  function toggleAttachmentMenu() {
    setShowFormattingMenu(false);
    openModal(<EmailModalAttachment workflow={workflow} attachments={attachments!} addAttachment={addAttachment} removeAttachment={removeAttachment} showMicrosoft postSigningStage />)
  }

  function toggleLinkMenu() {
    setShowFormattingMenu(false);

    if (editorContent.current && 'getEditor' in editorContent.current)
      openModal(<WorkflowModalLink editor={editorContent.current.getEditor?.()} />)
  }

  function toggleImageMenu() {
    setShowFormattingMenu(false);

    if (editorContent.current && 'getEditor' in editorContent.current)
      openModal(<WorkflowModalImage editor={editorContent.current.getEditor()} />)
  }
  function handleAttemptClose() {
    if (allowEditing && saveState === "HAS_CHANGES")
      return openModal(<WorkflowBlockModalSave onSave={() => handleSave().then(() => history.push(url))} onDontSave={() => history.push(url)} />);

    history.push(url);
  }

  const handleSwitchMode = () => {
    if ((email?.isHtmlMode && sanitizedHTML !== '') || (!email?.isHtmlMode && editorContent.current?.getTextValue() !== ''))
      openModal(
        <EmailInputModeModal
          onConfirm={onContentModeSwitch}
          title={`Switch to ${email?.isHtmlMode ? 'default editor' : 'HTML editor'}?`}
        />
      )
    else {
      onContentModeSwitch();
    }
  }

  const onContentModeSwitch = () => {
    setEmail(prev => ({
      ...prev!,
      isHtmlMode: !email?.isHtmlMode,
      emailContent: prev?.isHtmlMode ? '' : prev!.emailContent
    }));
  }

  if (!initialEmail || !email) {
    if (!location.pathname.endsWith("/email"))
      return null;

    return (
      <div className="flex flex-col items-center justify-center gap-4 h-full w-full">
        <div className="font-semibold font-gilroy text-gray-600 text-center w-96">
          This workflow contains no email templates, would you like to add some?
        </div>
        <button className="btn btn-blue" onClick={() => openModal(<WorkflowSelectModalAdd title="Add Workflow Template" subtitle="Add a template to your workflow" showEmails {...workflowContext} />)}>
          Add Email Template
        </button>
      </div>
    );
  }

  return (
    <LastFocusedEditorContext.Provider value={{ editorRef: lastFocusedEditor, setEditorRef: setLastFocusedEditor }}>
      <TransitionWorkflowPage show={location.pathname.endsWith("/email")}>

        {allowEditing &&
          <TransitionWorkflowPageSideBar>

            {!workflow.source && <>
              <div className="flex flex-col gap-4 justify-center h-full">
                <div className="flex items-center">
                  <DatabaseIcon className="w-6 h-6 mr-2" />
                  <div className="font-gilroy font-semibold text-lg">
                    Connect a Source
                  </div>
                </div>
                <div className="flex flex-col gap-2 font-semibold text-gray-600">
                  Create a batch of documents from a spreadsheet
                  <div className="flex items-center gap-2">
                    <div className="h-px bg-gray-600 w-full" />
                    <div className="flex-shrink-0">or</div>
                    <div className="h-px bg-gray-600 w-full" />
                  </div>
                  Auto-Create documents from an event or form&nbsp;submission
                </div>
                <button className="btn btn-blue" onClick={() => openModal(<WorkflowSelectModal title="Select Source" subtitle="Create or select a source where your data will come from" showSources {...workflowContext} />)}>
                  Connect
                </button>
              </div>
            </>}

            {workflow.source && <>

              <WorkflowSourceFieldTable workflow={workflow} mutateWorkflow={mutateWorkflow} />

              <div className='flex flex-col gap-1'>
                <div className='text-sm font-semibold font-gilroy text-gray-600'>
                  Email Tracking
                </div>
                <div className='flex items-center gap-2'>
                  <ToggleButton value={email.enableTracking} onChange={updateEnableTracking} />
                  <div className='text-xs font-semibold text-gray-400 flex-shrink'>
                    View when each email is opened
                  </div>
                  <ProLabel />
                </div>
              </div>

              <div className='flex flex-col gap-1'>
                <div className='text-sm font-semibold font-gilroy text-gray-600'>
                  Portant branding
                </div>
                <div className='flex items-center gap-2'>
                  <ToggleButton value={!!user?.subscription?.featureRemoveBranding} onChange={handleIncludeBrandingToggle} disabled={!user?.subscription?.featureRemoveBranding} />
                  <div className='text-xs font-semibold text-gray-400 flex-shrink'>
                    Remove "Sent via Portant" in email
                  </div>
                  <ProLabel />
                </div>
              </div>

              {(workflow.team && false) && // Currently disabled
                <div className='flex flex-col gap-1'>
                  <div className='text-sm font-semibold font-gilroy text-gray-600'>
                    Send from Workflow Owner
                  </div>

                  <div className='flex flex-col'>
                    <div className='flex items-center gap-2'>
                      <ToggleButton value={email!.sendFromOwner} onChange={updateSendFromOwner} disabled={!isWorkflowOwner} />
                      <div className='text-xs font-semibold text-gray-400 flex-shrink'>
                        Always send emails from the workflow owner
                      </div>
                    </div>
                    {!isWorkflowOwner &&
                      <div className='text-xs font-semibold text-gray-600 w-full text-center mt-1'>
                        This settings can only be configured by the workflow&nbsp;owner: <b>{workflow.owner?.fullName}</b>
                      </div>
                    }
                  </div>
                </div>
              }

              {workflow.documents.length > 0 &&
                <button className='text-sm btn btn-blue' onClick={toggleAttachmentMenu}>
                  Add Attachments <PaperClipIcon className='w-4 h-4 ml-2 text-white' />
                </button>
              }

              {workflow.documents.length === 0 && <>
                <hr />
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col gap-1">
                    <div className="flex items-center font-gilroy font-semibold text-gray-600">
                      <DocumentTextIcon className="w-6 h-6 mr-1" /> Add document
                    </div>
                    <div className="font-semibold text-xs text-gray-400">
                      Add a templated document to be included with this email
                    </div>
                  </div>
                  <button className="btn btn-white w-40 text-xs" onClick={() => openModal(<WorkflowSelectModal title="Add document" subtitle="Add a templated document to be included with this email" showDocuments {...workflowContext} />)}>
                    Add
                  </button>
                </div>
              </>}

              <hr />
              <WorkflowPaymentConfig />

            </>}
          </TransitionWorkflowPageSideBar>
        }

        <Transition.Child className="flex flex-col bg-white w-[800px] m-auto border border-gray-200 shadow rounded overflow-hidden"
          enter="transition-opacity duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className='bg-gray-100 flex items-center gap-2 px-2 py-1 border-b border-gray-200'>
            <img className="h-4 w-4 rounded-md" src={BLOCK_ICON_MAP[email.emailType]} alt="Gmail Icon" />
            <div className='font-gilroy font-semibold text-gray-600 text-sm'>
              {email.emailType === "GMAIL" ? <>Share via Gmail</> : <span className='flex gap-3'>Share via Outlook <BetaLabel /></span>}
            </div>
            <XIcon className="w-5 h-5 ml-auto text-gray-600 hover:text-red cursor-pointer" onClick={handleAttemptClose} />
          </div>

          <div className="flex flex-col h-full p-2">
            <div className="flex items-center w-full relative">
              <label className="w-20 font-gilroy font-medium text-gray-500" htmlFor="email-from">From</label>
              {email.emailType === "GMAIL"
                ? <EmailAliasPopover value={email.emailFromAlias} setValue={value => updateEmailField("emailFromAlias", value)} workflow={workflow} sendFromOwner={email.sendFromOwner} aliasDisplay={email.aliasDisplay} allowEditing={allowEditing} />
                : <div>{user!.microsoftCredentials?.microsoftEmailAddress ?? "Your Microsoft Outlook Account"}</div>
              }
            </div>
            <div className="flex items-center w-full relative mt-2">
              <label className="w-20 font-gilroy font-medium text-gray-500" htmlFor="email-to" onClick={() => editorTo.current?.focus()}>To</label>
              <TemplatedTextEditor
                initialValue={email.emailTo}
                placeholder="Type email addresses directly or insert {{Template Tags}}"
                className="h-10 px-2 w-full flex items-center whitespace-nowrap overflow-hidden"
                onChange={() => { setEmptyToField(editorTo.current!.getValue().length === 0); setSaveState("HAS_CHANGES") }}
                onFocus={() => setLastFocusedEditor(editorTo)}
                deserializer={deserializeFromText}
                serializer={serializeToText}
                ref={editorTo}
                disabled={!allowEditing}
              />
              <div className="absolute right-0 flex justify-end text-gray-500 bg-white pl-1">
                {(!showCc && allowEditing) && <button className="mr-2 font-semibold" onClick={() => setShowCc(true)} type="button">Cc</button>}
                {(!showBcc && allowEditing) && <button className="font-semibold" onClick={() => setShowBcc(true)} type="button">Bcc</button>}
              </div>
            </div>

            {emptyToField &&
              <div className="flex text-yellow text-sm items-center mb-1" onClick={() => editorTo.current?.focus()} >
                <ExclamationCircleIcon className="w-4 h-4 mr-1" />
                Please enter at least one recipient
              </div>
            }

            {showCc &&
              <div className="flex items-center w-full">
                <label className="w-20 font-gilroy font-medium text-gray-500" htmlFor="email-cc" onClick={() => editorCc.current?.focus()}>Cc</label>
                <div className="flex w-full items-center">
                  <TemplatedTextEditor
                    initialValue={email.emailCc}
                    className="h-10 px-2 w-full flex items-center whitespace-nowrap overflow-hidden"
                    onChange={() => setSaveState("HAS_CHANGES")}
                    onFocus={() => setLastFocusedEditor(editorCc)}
                    deserializer={deserializeFromText}
                    serializer={serializeToText}
                    ref={editorCc}
                    disabled={!allowEditing}
                  />
                  <TrashIcon className="w-6 h-6 mx-2 cursor-pointer text-gray-500 hover:text-red" onClick={() => { setShowCc(false); updateEmailField("emailCc", ""); }} />
                </div>
              </div>
            }

            {showBcc &&
              <div className="flex items-center w-full">
                <label className="w-20 font-gilroy font-medium text-gray-500" htmlFor="email-bcc" onClick={() => editorBcc.current?.focus()}>Bcc</label>
                <div className="flex w-full items-center">
                  <TemplatedTextEditor
                    initialValue={email.emailBcc}
                    className="h-10 px-2 w-full flex items-center whitespace-nowrap overflow-hidden"
                    onChange={() => setSaveState("HAS_CHANGES")}
                    onFocus={() => setLastFocusedEditor(editorBcc)}
                    deserializer={deserializeFromText}
                    serializer={serializeToText}
                    ref={editorBcc}
                    disabled={!allowEditing}
                  />
                  <TrashIcon className="w-6 h-6 mx-2 cursor-pointer text-gray-500 hover:text-red" onClick={() => { setShowBcc(false); updateEmailField("emailBcc", ""); }} />
                </div>
              </div>
            }

            <hr className="border-gray-400" />

            <div className="flex items-center w-full mt-2">
              <label className="w-20 font-gilroy font-medium text-gray-500" htmlFor="email-subject" onClick={() => editorSubject.current?.focus()}>Subject</label>
              <TemplatedTextEditor
                initialValue={email.emailSubject}
                className="h-10 px-2 w-full flex items-center whitespace-nowrap overflow-hidden"
                onChange={() => { setEmptySubjectField(editorSubject.current!.getValue().length === 0); setSaveState("HAS_CHANGES") }}
                onFocus={() => setLastFocusedEditor(editorSubject)}
                deserializer={deserializeFromText}
                serializer={serializeToText}
                ref={editorSubject}
                disabled={!allowEditing}
              />
            </div>
            {emptySubjectField &&
              <div className="flex text-yellow text-sm items-center mb-1" onClick={() => editorSubject.current?.focus()}>
                <ExclamationCircleIcon className="w-4 h-4 mr-1" />
                Please provide a subject
              </div>
            }

            <hr className="border-gray-400 pb-2" />

            <div className="flex flex-col h-[400px]">

              {
                email.isHtmlMode ? (
                  <HTMLEmailEditor
                    initialValue={email.emailContent}
                    value={sanitizedHTML}
                    onChange={() => setSaveState("HAS_CHANGES")}
                    setValue={setSanitizedHTML}
                  />
                ) : (
                  <div className="relative w-full overflow-y-auto h-full">
                    <EmailEditor
                      ref={editorContent}
                      className="h-full"
                      initialValue={email.emailContent}
                      showFormattingMenu={showFormattingMenu}
                      formattingMenuContainer={formattingMenuContainer}
                      onChange={() => setSaveState("HAS_CHANGES")}
                      onFocus={() => setLastFocusedEditor(editorContent as any)}
                      disabled={!allowEditing}
                    />
                  </div>
                )
              }

              <div className='mt-auto flex-shrink-0 flex flex-col pt-2'>
                {addingAttachment &&
                  <div className="select-none cursor-default flex items-center bg-gray-200 py-1 px-2 rounded-sm shadow-sm max-w-[412px] mb-1">
                    <div className='w-4 h-4 mr-2 flex-shrink-0' />
                    <div className={`text-gray-600 text-sm font-semibold truncate w-96`}>
                      Adding attachment...
                    </div>
                  </div>
                }

                {attachments?.map((attachment: Attachment) =>
                  <div key={attachment.id} className="select-none cursor-default flex items-center bg-gray-200 py-1 px-2 rounded-sm shadow-sm max-w-[412px] mb-1">
                    <img className='w-4 h-4 mr-2 flex-shrink-0' src={MIMETYPE_ICON_MAP[attachment.attachmentMimeType ?? "application/pdf"] ?? BLOCK_ICON_MAP["GOOGLE_DRIVE"]} alt="Attachment icon" />
                    <div className={`text-gray-600 text-sm font-semibold truncate w-96 ${removingAttachmentId === attachment.id ? "line-through" : ""}`} onClick={() => attachment.driveFile && window.open(attachment.driveFile?.url)}>
                      {attachment.attachmentName}
                    </div>
                    {attachment.attachmentType === "INSERT_OUTPUT_AS_LINK" &&
                      <div className='text-xs mr-2'>
                        <select value={attachment.sharingMode} className='font-gilroy font-semibold bg-gray-50 shadow rounded py-px' onChange={e => updateAttachment(attachment.id, e.currentTarget.value)} disabled={updatingAttachmentId === attachment.id}>
                          <option value="VIEWER">Viewer</option>
                          <option value="COMMENTER">Commenter</option>
                          <option value="EDITOR">Editor</option>
                        </select>
                      </div>
                    }
                    {allowEditing &&
                      <XIcon className="w-5 h-5 text-gray-400 hover:text-gray-600 ml-auto cursor-pointer flex-shrink-0" onClick={() => removeAttachment(attachment.id)} />
                    }
                  </div>
                )}
              </div>
              {allowEditing &&
                <div className="flex flex-col flex-shrink-0">
                  <div ref={formattingMenuContainer} />

                  <div className="my-1 flex items-center z-10 flex-shrink-0 w-full">
                    <div className='flex mx-2 h-full gap-2 items-center'>
                      {
                        !email.isHtmlMode && (
                          <>
                            <WorkflowEmailToolbarButton isActive={showFormattingMenu} iconName="format_color_text" tooltipText="Formatting options" onClick={toggleFormattingMenu} />
                            <WorkflowEmailToolbarButton iconName="link" tooltipText="Insert link" onClick={toggleLinkMenu} />
                            <WorkflowEmailToolbarButton iconName="insert_photo" tooltipText="Insert photo" onClick={toggleImageMenu} />
                          </>
                        )
                      }
                      <WorkflowEmailToolbarButton iconName="attach_file" tooltipText="Attach files" onClick={toggleAttachmentMenu} />
                      <div className='mx-2 bg-gray-400 h-[20px] w-px my-2' />

                      <button
                        className="flex items-center gap-1 font-semibold font-gilroy text-gray-600 text-sm hover:text-blue hover:underline cursor-pointer"
                        onClick={handleSwitchMode}
                      >
                        <CodeIcon className='h-6 w-6' />
                        Switch to {email.isHtmlMode ? 'default editor' : <div className='relative'>HTML editor <div className='absolute bg-blue text-white px-2 rounded shadow font-gilroy font-semibold text-xs -top-4 -right-6'>BETA</div></div>}
                      </button>

                      {email.isHtmlMode && (
                        <button
                          className="flex items-center ml-2 gap-1 font-semibold font-gilroy text-gray-600 text-sm hover:text-blue hover:underline cursor-pointer"
                          onClick={() => openModal(<HTMLPreviewModal htmlString={sanitizedHTML} />)}
                        >
                          <EyeIcon className='h-6 w-6' />
                          Display preview
                        </button>
                      )}
                    </div>

                    {(saveState === "INITIAL") &&
                      <button className=" ml-auto btn btn-blue w-32" disabled>
                        Save
                      </button>
                    }
                    {(saveState === "HAS_CHANGES") &&
                      <button className=" ml-auto btn btn-blue w-32" onClick={handleSave} disabled={!allowEditing}>
                        Save
                      </button>
                    }
                    {(saveState === "SAVING") &&
                      <button className=" ml-auto btn btn-blue w-32" disabled>
                        Saving...
                      </button>
                    }
                    {(saveState === "SAVED") &&
                      <button className=" ml-auto btn btn-green w-32">
                        Saved
                      </button>
                    }
                  </div>
                </div>
              }
            </div>
          </div>
        </Transition.Child>
      </TransitionWorkflowPage>
    </LastFocusedEditorContext.Provider >
  );

}

export default WorkflowEmail;
