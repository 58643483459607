import { useState } from "react";
import { OAuthError, postData } from "../backend";
import { useModal } from "../contexts";
import WorkflowSelectButton from "./WorkflowSelectButton";
import WorkflowSelectGoogleButton from "./WorkflowSelectGoogleButton";
import ErrorModal from "./ErrorModal";
import LoadingModal from "./LoadingModal";
import Modal from "./Modal";
import OAuthModalHubspot from "./OAuthModalHubspot";
import OAuthModalXero from "./OAuthModalXero";
import OAuthModalMicrosoft from "./OAuthModalMicrosoft";
import WorkflowSelectMicrosoftButton, { PickerResponse as MicrosoftPickerResponse } from "./WorkflowSelectMicrosoftButton";
import OAuthModalCopper from "./OAuthModalCopper";
import { BasicWorkflow } from "../interfaces";
import WorkflowSelectPDFButton from "./WorkflowSelectPDFButton";

interface Props {
  onSelection: (stage: "SOURCE" | "DOCUMENT", blockType: string, file?: any, isPdf?: boolean) => void;
  openBlock?: string;
}

type OAuthProps = {
  oauthUrl: string;
  onClose?: () => void;
}

function WorkflowSelectModalNew(props: Props) {
  const [openBlock, setOpenBlock] = useState<string | null>(props.openBlock ?? null);

  const { openModal } = useModal();

  async function handleSelectSource(blockType: string, file: any) {
    props.onSelection("SOURCE", blockType, file);
  }

  async function handleSelectDocument(blockType: string, file: any, isPdf?: boolean) {
    props.onSelection("DOCUMENT", blockType, file, isPdf);
  }

  async function handleSelectAuthedSource(AuthComponent: (props: OAuthProps) => JSX.Element, name: string, newWorkflow?: BasicWorkflow) {
    openModal(<LoadingModal />);

    try {
      await postData(`/${name.toLowerCase()}/access-token/`);
      if (newWorkflow)
        props.onSelection("SOURCE", name.toUpperCase(), newWorkflow);
      else
        props.onSelection("SOURCE", name.toUpperCase());

    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<AuthComponent oauthUrl={error.oauthUrl} onClose={() => handleSelectAuthedSource(AuthComponent, name, newWorkflow)} />);
      else
        openModal(<ErrorModal details={error} />);
    }
  }
  async function handleSelectMicrosoft(response: MicrosoftPickerResponse, newWorkflow?: BasicWorkflow) {
    openModal(<LoadingModal />);

    try {
      await postData("/microsoft/access-token/");
      props.onSelection("SOURCE", "MICROSOFT_EXCEL", response.value[0]);
      if (newWorkflow)
        props.onSelection("SOURCE", "MICROSOFT_EXCEL", { ...response.value[0], newWorkflow });
      else
        props.onSelection("SOURCE", "MICROSOFT_EXCEL", response.value[0]);
    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<OAuthModalMicrosoft oauthUrl={error.oauthUrl} />);
      else
        openModal(<ErrorModal details={error} />);
    }
  }

  return (
    <Modal
      title="New Workflow"
      subtitle="Select a source or template document to start building a workflow"
    >
      <div className="flex flex-col gap-4 overflow-y-auto overflow-x-visible max-h-[500px] pr-6 -mr-6">
        <div className="flex flex-col w-full gap-2">
          <div className="font-gilroy font-semibold text-gray-600">
            Templates
          </div>
          <WorkflowSelectGoogleButton
            blockType="GOOGLE_DOCS"
            title="Google Docs"
            subtitle="Create documents from a Google Docs template"
            onSelection={handleSelectDocument}
            openBlock={openBlock}
            setOpenBlock={setOpenBlock}
            isNewWorkflow
          />
          <WorkflowSelectGoogleButton
            blockType="GOOGLE_SLIDES"
            title="Google Slides"
            subtitle="Create presentations from a Google Slides template"
            onSelection={handleSelectDocument}
            openBlock={openBlock}
            setOpenBlock={setOpenBlock}
            isNewWorkflow
          />
          <WorkflowSelectPDFButton blockType="PDF_FILLABLE" title="Fillable PDF" subtitle="Merge data in a PDF template that contains form fields" onSelection={handleSelectDocument} onSelectPdfFile={(bt, f) => handleSelectDocument(bt, f, true)} openBlock={openBlock} setOpenBlock={setOpenBlock} />
        </div>
        <div className="flex flex-col w-full gap-2">
          <div className="font-gilroy font-semibold text-gray-600">
            Sources
          </div>
          <WorkflowSelectGoogleButton
            blockType="GOOGLE_SHEETS"
            title="Google Sheets"
            subtitle="Create documents from rows in your spreadsheet"
            onSelection={handleSelectSource}
            openBlock={openBlock}
            setOpenBlock={setOpenBlock}
            isNewWorkflow
          />
          <WorkflowSelectGoogleButton
            blockType="GOOGLE_FORMS"
            title="Google Forms"
            subtitle="Create documents from responses in your form"
            onSelection={handleSelectSource}
            openBlock={openBlock}
            setOpenBlock={setOpenBlock}
            isNewWorkflow
          />
          <WorkflowSelectButton
            blockType="HUBSPOT"
            title="HubSpot"
            subtitle="Integrate with HubSpot to run automations on Deals"
            onSelection={(name, file) => handleSelectAuthedSource(OAuthModalHubspot, name, file)}
            isNewWorkflow
          />
          <WorkflowSelectButton
            blockType="COPPER"
            title="Copper"
            subtitle="Integrate with Copper to run automations on opportunities"
            onSelection={(name, file) => handleSelectAuthedSource(OAuthModalCopper, name, file)}
            isNewWorkflow
          />
          <WorkflowSelectButton
            blockType="GLIDE"
            title="Glide"
            subtitle="Integrate with Glide to create documents from Glide apps"
            onSelection={handleSelectSource}
            isNewWorkflow
          />
          <WorkflowSelectButton
            blockType="TALLY"
            title="Tally"
            subtitle="Create documents when a response is submitted in Tally"
            onSelection={handleSelectSource}
            isNewWorkflow
          />
          {/* TODO: Enable Zapier when listing is ready */}
          {/* <WorkflowSelectButton blockType="ZAPIER" title="Zapier" subtitle="Use a Portant workflow as an action in Zapier" onSelection={handleSelectSource} /> */}
          <WorkflowSelectButton
            blockType="XERO"
            title="Xero"
            subtitle="Create documents from invoices in Xero"
            onSelection={(name, file) => handleSelectAuthedSource(OAuthModalXero, name, file)}
            isNewWorkflow
          />
          <WorkflowSelectMicrosoftButton
            blockType="MICROSOFT_EXCEL"
            title="Microsoft Excel"
            subtitle="Create documents from rows in your Excel spreadsheet"
            onSelection={handleSelectMicrosoft}
            isNewWorkflow
          />
          <WorkflowSelectButton
            blockType="WEBHOOK"
            title="Webhook"
            subtitle="Create documents from any incoming webhook event"
            onSelection={handleSelectSource}
            isNewWorkflow
          />
        </div>
      </div>
    </Modal>
  );
}

export default WorkflowSelectModalNew;
